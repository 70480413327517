export const environment = {
  production: true,
  name: "dev",
};

export const TOKEN = {
  auth_code: "cim",
  avp_token: "token",
};

export const STATIC_URL = {
  homepage_url() {
    return "https://www-stg2.allianz.de";
  },
  email_address_url() {
    return "https://www-stg2.allianz.de/meine-allianz/daten/#/digitale-kommunikation";
  },
  account_summary() {
    return "https://www-stg2.allianz.de/meine-allianz";
  },
};

export const notInPrimeCloud = `https://www-stg2.allianz.de/meine-allianz/daten/#/vorteilsprogramm-anmelden`;
export const ERROR_404_PAGE = "https://www-stg2.allianz.de/meine-allianz";
const DRUPAL_URL = "https://allianzt.loyalty-prime.cloud";
export const DRUPAL_BASE_URL = "https://allianzt.loyalty-prime.cloud";
const API_VERSION = "/api/v1";
export const MEMBER_PASS = "fs23fl@d1*!2GHyzk56%5Es%25*df9Ga@dw";

export const API_URL = `${DRUPAL_URL}${API_VERSION}`;
export const PD_URL = `https://praemienshopt.allianz.de`;

export const WEWE_URL =
  "https://www-stg2.allianz.de/meine-allianz/daten/#/werbeeinwilligung-zustimmen-avp";

export const RedirectMaintenance = "https://www-stg2.allianz.de/meine-allianz";

export const NewsletterUnsubscribeSecretKey =
  "QDYpNY7KwAC+SF5qimPx3skDimeS7QjC6jtZ6dnZreg=";

export const ActivateAccess = "https://test-cim.allianz.de/freischaltung";
export const SCRIPTS = {
  COOKIE:
    "https://www.allianz.de/azonetrust/AZD/oneTrust_production/scripttemplates/otSDKStub.js",
  DYNATRACE:
    "https://js-cdn.dynatrace.com/jstag/171306995be/bf13169nae/5df74a0a873325e9_complete.js",
  ATL: "https://www.allianz.de/azutils/prod/atl/allianz-tracking-lib.js",
  ATL_CONFIG: "https://www.allianz.de/azutils/dev/atl/atl-config-external.js",
};

export const COOKIE_DATA_DOMAIN_SCRIPT = "1668964e-5449-4848-853f-d5c40f316e90";

export const CIM = {
  username: "07nwiw16mnfhsfv8vyd83udtouoju6rjwl764dbb",
  password: "ijl9pxufvzpsu85dd5nm",
  client_id: "07nwiw16mnfhsfv8vyd83udtouoju6rjwl764dbb",
  AMBaseURL: "https://test-cim.allianz.de/auth",
  CIM_URI: "https://test-cim.allianz.de",
  realmPath: "realms/root/realms/eu1",
  scope: encodeURIComponent("openid email profile az_basic"),
  response_type: "code",
  redirect_uri: "https://meinevorteilet.allianz.de",
  post_logout_redirect_uri:
    "https://www-stg2.allianz.de/service/meine-allianz/logout/ ",
  url() {
    return `${this.AMBaseURL}/oauth2/${this.realmPath}/authorize?client_id=${this.client_id
      }&state=${localStorage.getItem("tempPathname")}&scope=${this.scope
      }&response_type=${this.response_type}&redirect_uri=${this.redirect_uri}`;
  },
  logoutUrl(oid: string) {
    return `${this.AMBaseURL}/oauth2/${this.realmPath}/connect/endSession?id_token_hint=${oid}`;
  },
};
export const FEEDBACK_REDIRECT = "https://allianz-deutschland.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=AECSWC&OE=1&lg=German&OE_12=81&subjourney=6&lineOfBusiness=3&branch=10&journey=4&OE_10=1&OE_11=30&Zielgruppe=1&interactionPointID=D-MAZ-ELSE&OE_8=44&OE_9=25&DuSie1=1&DuSie2=0&DuSie3=0&DuSie4=0&DuSie5=0&DuSie6=0&DuSie7=0";

export const LOTTERY_FEEDBACK_REDIRECT = "https://allianz-deutschland.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=AECSWC&OE=1&lg=German&design=1&OE_12=148&subjourney=6&lineOfBusiness=3&branch=10&journey=4&OE_10=1&OE_11=0&Zielgruppe=1&interactionPointID=D-MAZ-ELSE&OE_8=82&OE_9=51&DuSie1=1&DuSie2=0&DuSie3=0&DuSie4=0&DuSie5=0&DuSie6=0&DuSie7=0"
export const TICKETSHOP_BETA_URL = "http://ticketshop-dev.allianz.de/";
